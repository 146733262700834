import React, { useState } from "react"
import Layout from "../layouts/layout"
import { Auth } from "aws-amplify"
import { Spinner } from "@blueprintjs/core"
import { Authenticator, SignUp, Greetings } from "aws-amplify-react"
import { navigate } from "@reach/router"
import SEO from "../components/seo"

const Login = props => {
  const [loading, setLoading] = useState(false)
  const [firstStateChecked, setFSC] = useState(false)

  const checkAuthForRedirect = () => {
    Auth.currentSession()
      .then(async session => {
        const groups = session.idToken.payload["cognito:groups"]

        if (!groups) {
          navigate("/results")
        } else {
          if (groups.includes("admin")) {
            navigate("/admin")
          } else {
            navigate("/results")
          }
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }

  if (loading) return <Spinner intent="primary" className="mx-auto" size={40} />

  return (
    <Layout>
      <SEO title="Login" />
      <Authenticator
        onStateChange={state => {
          if (firstStateChecked) {
            if (state === "signIn") {
              setLoading(true)
            } else if (state === "signedIn") {
              checkAuthForRedirect()
            } else if (state === "forgotPassword") {
              setFSC(false)
            }
          } else {
            setFSC(true)
            checkAuthForRedirect()
          }
        }}
        hide={[SignUp, Greetings]}
      />
    </Layout>
  )
}

export default Login
